<template>
  <div class="account">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "account",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.account {
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
